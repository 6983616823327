/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBatchRoutes = /* GraphQL */ `
  query GetBatchRoutes($ids: [String]) {
    getBatchRoutes(ids: $ids) {
      agency_id
      route_id
      route_short_name
      __typename
    }
  }
`;
export const getBatchStops = /* GraphQL */ `
  query GetBatchStops($ids: [String]) {
    getBatchStops(ids: $ids) {
      headsigns {
        agency_id
        headsign
        route_short_name
        __typename
      }
      platform_code
      nearby
      stop_id
      stop_kana
      stop_lat
      stop_lon
      stop_name
      stop_url
      __typename
    }
  }
`;
export const getBatchTrips = /* GraphQL */ `
  query GetBatchTrips($ids: [String]) {
    getBatchTrips(ids: $ids) {
      route_id
      trip_headsign
      trip_id
      __typename
    }
  }
`;
export const getRoutes = /* GraphQL */ `
  query GetRoutes($route_id: String!) {
    getRoutes(route_id: $route_id) {
      agency_id
      route_id
      route_short_name
      __typename
    }
  }
`;
export const getStopTimes = /* GraphQL */ `
  query GetStopTimes($stop_sequence: Int!, $trip_id: String!) {
    getStopTimes(stop_sequence: $stop_sequence, trip_id: $trip_id) {
      departure_time
      pickup_type
      stop_headsign
      stop_id
      stop_sequence
      timestamp
      trip_id
      __typename
    }
  }
`;
export const getStops = /* GraphQL */ `
  query GetStops($stop_id: String!) {
    getStops(stop_id: $stop_id) {
      headsigns {
        agency_id
        headsign
        route_short_name
        __typename
      }
      platform_code
      nearby
      stop_id
      stop_kana
      stop_lat
      stop_lon
      stop_name
      stop_url
      __typename
    }
  }
`;
export const getTrips = /* GraphQL */ `
  query GetTrips($trip_id: String!) {
    getTrips(trip_id: $trip_id) {
      route_id
      trip_headsign
      trip_id
      __typename
    }
  }
`;
export const listRoutes = /* GraphQL */ `
  query ListRoutes(
    $filter: TableRoutesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoutes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        agency_id
        route_id
        route_short_name
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStopTimes = /* GraphQL */ `
  query ListStopTimes(
    $filter: TableStopTimesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStopTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        departure_time
        pickup_type
        stop_headsign
        stop_id
        stop_sequence
        timestamp
        trip_id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStops = /* GraphQL */ `
  query ListStops(
    $filter: TableStopsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        platform_code
        nearby
        stop_id
        stop_kana
        stop_lat
        stop_lon
        stop_name
        stop_url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTrips = /* GraphQL */ `
  query ListTrips(
    $filter: TableTripsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        route_id
        trip_headsign
        trip_id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryStopTimesByStopIdIndex = /* GraphQL */ `
  query QueryStopTimesByStopIdIndex(
    $after: String
    $first: Int
    $stop_id: String!
  ) {
    queryStopTimesByStopIdIndex(
      after: $after
      first: $first
      stop_id: $stop_id
    ) {
      items {
        departure_time
        pickup_type
        stop_headsign
        stop_id
        stop_sequence
        timestamp
        trip_id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryStopTimesByTripId = /* GraphQL */ `
  query QueryStopTimesByTripId($after: String, $first: Int, $trip_id: String!) {
    queryStopTimesByTripId(after: $after, first: $first, trip_id: $trip_id) {
      items {
        departure_time
        pickup_type
        stop_headsign
        stop_id
        stop_sequence
        timestamp
        trip_id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
