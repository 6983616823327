import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  hhmm,
  initBaseTimestamp,
  delayMin,
  spentSec,
  toHalfWidth,
  HOUR9SEC,
} from "../Common/CommonFunc";
import { agencies } from "../Common/Agencies";

export function CardList(props) {
  const stopTimes = props.stopTimes;
  const tripMap = props.tripMap;
  const routeMap = props.routeMap;
  const uncertain = props.uncertain === true ? true : false;
  const cards = [];
  const timestamp0 = initBaseTimestamp(); // 遅れを計算する前に実行
  for (const stopTime of stopTimes) {
    const trip = tripMap.get(stopTime.trip_id);
    const route = routeMap.get(trip.route_id);
    const departureTime = hhmm(stopTime.departure_time); // 表示用
    const departure_time = stopTime.departure_time; // 計算用
    const datetimeStr =
      new Date((timestamp0 + spentSec(departure_time) + HOUR9SEC) * 1000)
        .toISOString()
        .slice(0, -1) + "+09:00";
    const stop_sequence = stopTime.stop_sequence;
    const tripUrl = "/trip/" + trip.trip_id + "?seq=" + stop_sequence;
    let headsign = stopTime.stop_headsign;
    if (!headsign || headsign.lenght < 1) {
      headsign = trip.trip_headsign;
    }
    const route_short_name = toHalfWidth(route.route_short_name);
    const agency_name = agencies.get(route.agency_id).agency_name;
    const agency_color = agencies.get(route.agency_id).agency_color;

    // 遅れ
    let delay_str = "";
    let tColor = "text.primary";
    const timestamp = stopTime.timestamp;
    if (timestamp && !uncertain) {
      let delay_min = delayMin(timestamp, departure_time);
      if (delay_min < 0) delay_min = 0;
      if (delay_min === 0) delay_str = "定刻";
      else {
        delay_str = "遅れ" + delay_min + "分";
        tColor = "error.main";
      }
    }
    if (uncertain) {
      tColor = "error.main";
      if (!timestamp) {
        delay_str = "バスロケなし";
      } else {
        let delay_min = delayMin(timestamp, departure_time);
        if (delay_min < 0) delay_min = 0;
        delay_str = "遅れ" + delay_min + "分?";
      }
    }

    cards.push(
      <Card
        key={stopTime.trip_id}
        variant="outlined"
        sx={{ bgcolor: "#e0e0e0" }}
      >
        <CardActionArea>
          <Link color="inherit" underline="none" href={tripUrl}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  p: 0.5,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    component="time"
                    variant="h5"
                    sx={{ p: 0.5 }}
                    dateTime={datetimeStr}
                  >
                    {departureTime}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Stack sx={{ mr: 0.5 }}>
                    <DirectionsBusIcon sx={{ color: agency_color }} />
                  </Stack>
                  <Typography>{agency_name}</Typography>
                </Box>
                <Box>
                  <Typography
                    component="div"
                    variant="h6"
                    color={tColor}
                    sx={{ p: 0.5 }}
                  >
                    {delay_str}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  p: 0.5,
                  borderRadius: 1,
                  bgcolor: "#757575",
                  color: "#ffffff",
                }}
              >
                <Box
                  sx={{ color: "secondary.main", border: 2, borderRadius: 1 }}
                >
                  <Typography sx={{ p: 0.5 }} component="div" variant="h6">
                    {route_short_name}
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1, textAlign: "center", p: 0.5 }}>
                  <Typography component="div" variant="h6">
                    {headsign}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Link>
        </CardActionArea>
      </Card>
    );
  }

  return <>{cards}</>;
}
